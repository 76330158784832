<template>
  <div
    class="form container my-10"
  >
    <h2>{{ isEdit ? 'Редактировать' : 'Добавить' }} Водителя {{ isEdit ? `[${params.item.id}]` : '' }}</h2>

    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="form.last_name"
          label="Фамилия"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.first_name"
          label="Имя"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.middle_name"
          label="Отчество"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.country_letter"
          label="Страна"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.identity_doc_code"
          label="Код документа"
          auto-select-first
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :items="identityDocumentsFiltered"
          item-text="search"
          item-value="code"
          :filter="filterBySearchField"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.identity_doc_number"
          label="Номер"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <custom-date-picker
          v-model="form.identity_doc_date"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col
        v-if="!contacts.length"
        class="mt-4"
        cols="8"
      >
        <v-btn
          dense
          class="elevation-0"
          @click="addContactItem"
        >
          <v-icon>mdi-plus</v-icon> Контакт
        </v-btn>
      </v-col>
      <v-col
        v-if="contacts.length"
        cols="8"
      >
        <single-contact
          v-for="(contact, index) in contacts"
          :ref="'cont_' + index"
          :key="index"
          :types="contact_types"
          :item="contact"
          :idx="index"
          :contacts-length="contacts.length"
          @deleteContactItem="deleteContactItem"
          @addContactItem="addContactItem"
          @contactsUpdater="contactsUpdater"
        />
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <div class="d-flex mt-4">
      <v-btn
        class="mr-4"
        color="#5CB7B1"
        dark
        @click="submit"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="red"
        dark
        @click="clear"
      >
        Очистить
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {
  getCountryNameByCode,
} from "@/helpers/catalogs";
import cloneDeep from "lodash.clonedeep";
import SingleContact from "@/components/shared/single-contact.vue";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";

export default {
  components: {CustomDatePicker, SingleContact},
  mixins: [filterBySearchFieldMixin],
  props: {
    params: {
      required: false,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    }
  },
  data: () => ({
    form: {
      id: null,
      division_id: null,
      country_letter: null,
      country_name: null,
      identity_doc_code: null,
      identity_doc_number: null,
      identity_doc_date: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      user_id: null,
    },
    contacts: [{
      code: null,
      name: null,
      contact: null
    }],
    nsiTransportMarks: []
  }),
  computed: {
    ...mapGetters({
      countries: "catalogs/getNsiCountries",
      user: "auth/getUser",
      contact_types: "catalogs/getContactTypes",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
    }),
    userId(){
      return this.user?.user?.id
    },
    isEdit() {
      return !!(this.params.item)
    },
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    identityDocumentsFiltered() {
      if (this.form.country_letter) {
        if (
          ["BY", "RU", "KZ", "KG", "AM"].includes(
            this.form.country_letter
          )
        ) {
          return this.identityDocuments.filter((item) => {
            return item.code.startsWith(this.form.country_letter);
          });
        }
        return this.identityDocuments.filter((item) => {
          return item.code.startsWith("XX");
        });
      }
      return this.identityDocuments;
    },
  },
  watch: {
    params: {
      handler(nv) {
        if (nv.item) {
          this.setFields()
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item;
    },
    getContactsWithName(source) {
      return source.map((item) => {
        const { name } =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    addContactItem() {
      const contact ={
        code: null,
        name: null,
        number: null,
      }
      this.contacts.push(contact);
    },
    deleteContactItem(index) {
      this.contacts.splice(index, 1);
    },
    setFields() {
      const item = this.params.item
      console.log(item)
      Object.keys(this.form).forEach(key => {
        if (key in item) {
          this.form[key] = item[key]
        }
      })
      this.contacts = cloneDeep(item.contacts);
    },
    submit() {
      const country_name = getCountryNameByCode(this.countries, this.form.country_letter)
      const division_id = this.isEdit ? this.form.division_id : this.divisionId
      const contacts = this.getContactsWithName(this.contacts)
      const payload = {
        ...this.form,
        user_id:this.userId,
        country_name,
        division_id,
        contacts
      }
      this.$store.dispatch("catalogs/saveDriver", payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        }).catch(err => {
          if (err.response.status === 400) {
            return this.$snackbar({text: err?.response?.data || "Ошибка", color: "red", top: false, right: false});
          }
          this.$snackbar({text: err || "Ошибка", color: "red", top: false, right: false});
        });
    },
    clear() {
      this.form = {
        id: null,
        division_id: null,
        country_letter: null,
        country_name: null,
        identity_doc_code: null,
        identity_doc_number: null,
        identity_doc_date: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        user_id: null,
      }
      this.contacts = [{
        code: null,
        name: null,
        contact: null
      }]
    }
  }
}
</script>
<style scoped>
.form {
  width: 100%;
  min-height: calc(100vh - 129px)
}
</style>
